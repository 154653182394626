<template>
  <div>
    <v-card dense flat max-width="90%" class="mx-auto">
      <showFilteredPosts :personalMode="true" :postsStatus="'archived'" />
    </v-card>
    <!-- <OneTable
      :mainHeader="'ארכיון'"
      :postState="'Archives'"
      :key="'Archives'"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import showFilteredPosts from '@/components/showRecord/showFilteredPosts.vue'

// import OneTable from "@/components/app/common/Table/OneTable2";

export default {
  data: () => ({
    addDialog: false,
    values: {},
  }),
  components: {
    // OneTable,
    showFilteredPosts
  },
  computed: {
    ...mapGetters(["loading", "lagguageHeaders"]),
  },
};
</script>

<style></style>